import React from 'react';
import { LucideIcon } from 'lucide-react';

interface FeatureProps {
    Icon?: LucideIcon;
    number?: number;
    title: string;
    description: string;
}

const Feature: React.FC<FeatureProps> = ({ Icon, number, title, description }) => (
    <div className="flex flex-col items-center text-center mb-8">
        {Icon ? (
            <Icon className="w-12 h-12 mb-4 text-brand" />
        ) : number !== undefined ? (
            <div className="w-12 h-12 mb-4 flex items-center justify-center bg-brand rounded-full">
                <h1 className="text-2xl font-bold text-white">{number}</h1>
            </div>
        ) : null}
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

export default Feature;